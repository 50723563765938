import React, { Suspense, lazy } from 'react';
import './App.css';
import Header from './components/Header';
import Footer from './components/Footer';
import { Route, Routes } from "react-router-dom";
import Loader from './components/Loader';
import Invoice from './Invoice/Invoice';
import Genin from './Invoice/Genin';


// Lazy load components
const Section = lazy(() => import('./components/Section'));
const Blogs = lazy(() => import('./components/Blogs'));
const Products = lazy(() => import('./components/Products'));
const Details = lazy(() => import('./components/Details'));
const Contact = lazy(() => import('./components/Contact'));
const Network = lazy(() => import('./components/Network'));
const Faq = lazy(() => import('./components/Faq'));
const Signup = lazy(() => import('./components/Signup'));
const Notfound = lazy(() => import('./components/Notfound'));
const Features = lazy(() => import('./components/Feactures'));
const Service = lazy(() => import('./components/Service'));
const Map = lazy(() => import('./components/Map'));
const Rev = lazy(() => import('./components/Rev'));
const Support = lazy(() => import('./components/Support'));
const Productss = lazy(()=> import('./components/Productss')) ;
const Cloud = lazy(()=> import('./components/Cloud')); 
const Server = lazy(()=> import('./components/Server')) ;
const Dedicated = lazy(()=> import('./components/Dedicated')) ;
const Webp = lazy(()=> import('./components/Webp')) ;
const Term = lazy(()=> import('./components/Term')) ;
const Mail= lazy(()=> import('./components/Mail'));


function App() {
  return (
    <div>
      <Header />
      {/* Suspense will show Loader component until the lazy-loaded components are ready */}
      <Suspense fallback={<Loader />}>
        <Routes>
          <Route exact path="/" element={<Section />} />
          <Route exact path="/blogs" element={<Blogs />} />
          <Route  exact path="/services" element={<Products />} />
          <Route  exact path="/products" element={<Productss />} />
          <Route  exact path="/view/product/:id" element={<Details />} />
          <Route  exact path="/contact" element={<Contact />} />
          <Route  exact path="/network" element={<Network />} />
          <Route  exact path="/FAQ" element={<Faq />} />
          <Route  exact path="/user-sign" element={<Signup />} />
          <Route  exact path="/product-cloud" element={<Cloud />} />
          <Route  exact path="/product-server" element={<Server />} />
          <Route  exact path="/product-dedicated" element={<Dedicated />} />
          <Route  exact path="/product-web" element={<Webp />} />
          <Route  exact path="/terms" element={<Term />} />
          <Route  exact path="*" element={<Notfound />} />
          <Route  exact path="/invoice" element={<Invoice />} />
          <Route  exact path="/gotoinvoice" element={<Genin />} />
          <Route  exact path="/detail/product/:id" element={<Mail />} />
        </Routes>
        <Features />
        <Service />
        <Map />
        <Rev />
        <Support />
      </Suspense>
      <Footer />
    </div>
  );
}

export default App;
