import React, { useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import web from '../json/web.json';
import server from '../json/server.json';
import dedicated from '../json/dedicated.json';
import cloud from '../json/cloud.json';

export default function Invoice() {
    const [name, setName] = useState('');
    const [email, setEmail] = useState(''); // Added email state
    const [selectedPlan, setSelectedPlan] = useState('web');
    const [duration, setDuration] = useState('1');
    const [selectedSubPlan, setSelectedSubPlan] = useState('');
    const [isFormSubmitted, setIsFormSubmitted] = useState(false); // New state to manage button visibility
    
    const navigate = useNavigate();  // Initialize navigate

    // Plans data
    const webs = web["Web Hosting"]["Managed Web Hosting"].plans;
    const clouds = cloud["Cloud Servers"]["Virtual Cloud Servers"].plans;
    const dedicateds = dedicated["Dedicated Cloud"]["Enterprise Cloud Servers"].plans;
    const servers = server["Server Colocation"].plans;

    // Handler for selecting plan type
    const handlePlanChange = (e) => {
        setSelectedPlan(e.target.value);
        setSelectedSubPlan('');  // Reset the sub-plan when plan changes
    };

    // Get relevant sub-plans based on selected plan
    const getSubPlans = () => {
        switch (selectedPlan) {
            case 'web':
                return webs;
            case 'cloud':
                return clouds;
            case 'server':
                return servers;
            case 'dedicated':
                return dedicateds;
            default:
                return [];
        }
    };

    // Handle form submission and store data in localStorage
    const handleSubmit = (e) => {
        e.preventDefault();
        setIsFormSubmitted(true);  // Show "GO" button and hide "Make" button
    
        // Find the price of the selected sub-plan
        const selectedSubPlanData = getSubPlans().find(plan => plan.name === selectedSubPlan);
        const price = selectedSubPlanData ? selectedSubPlanData.price : "Price not available";
    
        const invoiceData = {
            name,
            email,  // Added email to the invoice data
            selectedPlan,
            selectedSubPlan,
            price,  // Store the price
            duration,
        };
    
        // Store the invoice data in localStorage
        localStorage.setItem('invoiceData', JSON.stringify(invoiceData));
        console.log(invoiceData);
    
        // Navigate to the Invoice Page (if needed)
        navigate('/invoice');  // Assuming the route is '/invoice'
    };
    
    return (
        <>
            <div className="py-6 sm:py-8 lg:py-12">
                <div className="mx-auto max-w-screen-2xl px-4 md:px-8">
                    <div className="mb-10 md:mb-16">
                        <h2 className="mb-4 text-center text-2xl font-bold text-gray-100 md:mb-6 lg:text-3xl">Get in touch</h2>
                        <p className="mx-auto max-w-screen-md text-center text-gray-100 md:text-lg">
                        </p>
                    </div>

                    <form className="mx-auto grid max-w-screen-md gap-4 sm:grid-cols-2" onSubmit={handleSubmit}>
                        <div>
                            <label htmlFor="first-name" className="mb-2 inline-block text-sm text-gray-100 sm:text-base">Name*</label>
                            <input
                                name="first-name"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                className="w-full rounded border bg-gray-50 px-3 py-2 text-gray-800 outline-none ring-indigo-300 transition duration-100 focus:ring"
                                required
                            />
                        </div>
                        <div>
                            <label htmlFor="email" className="mb-2 inline-block text-sm text-gray-100 sm:text-base">Email*</label>
                            <input
                        
                                name="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)} // Updated to setEmail
                                className="w-full rounded border bg-gray-50 px-3 py-2 text-gray-800 outline-none ring-indigo-300 transition duration-100 focus:ring"
                                required
                            />
                        </div>

                        <div className="sm:col-span-2">
                            <label htmlFor="plan" className="mb-2 inline-block text-sm text-gray-100 sm:text-base">Plan</label>
                            <select
                                className="w-full rounded border bg-gray-50 px-3 py-2 text-gray-800 outline-none ring-indigo-300 transition duration-100 focus:ring"
                                value={selectedPlan}
                                onChange={handlePlanChange}
                            >
                                <option value="web">Web</option>
                                <option value="server">Server</option>
                                <option value="dedicated">Dedicated</option>
                                <option value="cloud">Cloud</option>
                            </select>
                        </div>

                        <div className="sm:col-span-2">
                            <label htmlFor="duration" className="mb-2 inline-block text-sm text-gray-100 sm:text-base">Duration</label>
                            <select
                                className="w-full rounded border bg-gray-50 px-3 py-2 text-gray-800 outline-none ring-indigo-300 transition duration-100 focus:ring"
                                value={duration}
                                onChange={(e) => setDuration(e.target.value)}
                            >
                                <option value="1">1 Month</option>
                                <option value="3">3 Months</option>
                                <option value="6">6 Months</option>
                                <option value="12">12 Months</option>
                            </select>
                        </div>

                        <div className="sm:col-span-2">
                            <label htmlFor="sub-plan" className="mb-2 inline-block text-sm text-gray-100 sm:text-base">Sub-Plan</label>
                            <select
                                className="w-full rounded border bg-gray-50 px-3 py-2 text-gray-800 outline-none ring-indigo-300 transition duration-100 focus:ring"
                                value={selectedSubPlan}
                                onChange={(e) => setSelectedSubPlan(e.target.value)}
                                required
                            >
                                {getSubPlans().map((plan, index) => {
                                    console.log(`Plan: ${plan.name}, Price: ${plan.price}`); // Debugging line
                                    return (
                                        <option key={index} value={plan.name}>
                                            {plan.name} - {plan.price ? plan.price : "Price not available"}
                                        </option>
                                    );
                                })}
                            </select>
                        </div>

                        <div className="flex items-center justify-between sm:col-span-2">
                            {!isFormSubmitted && (
                                <button id='make' className="inline-block rounded-lg bg-orange-500 px-8 py-3 text-center text-sm font-semibold text-white outline-none ring-orange-300 transition duration-100 hover:bg-orange-600 focus-visible:ring active:bg-orange-700 md:text-base">
                                    Make
                                </button>
                            )}
                            {isFormSubmitted && (
                                <NavLink id='go' to='/gotoinvoice' className="inline-block rounded-lg bg-orange-500 px-8 py-3 text-center text-sm font-semibold text-white outline-none ring-orange-300 transition duration-100 hover:bg-orange-600 focus-visible:ring active:bg-orange-700 md:text-base">
                                    GO
                                </NavLink>
                            )}
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
}
